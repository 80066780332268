<template>
    <div>
        <van-nav-bar
          title="预约详情"
          left-text="返回"
          left-arrow
          @click-left="$router.go(-1)"
        />
        <div class="van-cell van-cell--center">
            <div class="van-cell__label">
                <div class="t">患者姓名： {{ data.name }}</div>
                <div class="t">患者年龄： {{ data.age }}</div>
                <div class="t">患者电话： {{ data.tel }}</div>
                <div class="t">预约项目： {{ data.exam_project }}</div>
                <div class="t">患者排号： {{ data.num }}</div>
                <div class="t">预约时段： {{ data.time_range }}</div>
                <div class="t">通知时间： {{ data.notify_time }}</div>
                <div class="t">是否已做： {{ data.state == 0 ? '未作':'已做' }}</div>
                <div v-if="data.state == 1">
                    <div class="t">确认时间： {{ data.do_time }}</div>
                    <div class="t">确认人员： {{ data.do_name }}</div>
                </div>
            </div>
        </div>
        <van-submit-bar v-if="data.state != 1"  text-align="center" button-text="确认已做" @submit="onSubmit" />
    </div>
</template>
<script>
import { SubmitBar, Toast, NavBar, Cell, Dialog, Notify  } from 'vant';
import axios from 'axios'
import user from '../../mixins/user.js'

export default {
    components: {
        [NavBar.name]: NavBar,
        [Cell.name]: Cell,
        [SubmitBar.name]: SubmitBar,
        [Toast.name]: Toast,
        [Dialog.name]: Dialog,
        [Notify.name]: Notify
    },

    data() {
        return {
            data: {},
            id: '',
            user: {}
        };
    },
    mixins: [
        user
    ],
    methods: {
        formatPrice(price) {
            return (price / 100).toFixed(2);
        },

        onSubmit() {
            Dialog.confirm({

                message: '确认患者已做完检查？',

            }).then(() => {
                console.log('here')
                this.confirm(this.data.id)
            }).catch(() => {
                console.log('there')
              });
        },
        onClickLeft() {
            console.log(this.$route)
            this.$route.back()
        },
        getItem(id) {
            axios.post('http://ydwx.83277777.com/api/bookexam/getItem', {id:id}).then(res => {
                    this.data = res.data.data
                    console.log(res.data.data)
                    
                }).catch((e) => {
                    console.log(e)
                })
        },
        confirm(id) {
           axios.post('http://ydwx.83277777.com/api/bookexam/confirm', {id:id, user: this.user.name}).then(res => {
                   
                   console.log(res.data)
                   if (res.data.success) {
                        this.data = res.data.data
                        Notify({ type: 'success', message: '操作成功' });
                   } else {
                        Notify({ type: 'danger', message: '操作失败，请联系管理员' });
                   }
                   
               }).catch((e) => {
                   Notify({ type: 'danger', message: e });
               }) 
        }
    },
    mounted() {
        console.log(this.$route.params)
        let q = this.$route.params
        this.getItem(q)
        console.log(this.user)
    }
};
</script>
<style lang="less">
.t {
    color: #323233;
    font-size: 14px;
    line-height: 24px;
}

</style>